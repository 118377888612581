import { Container } from '@mui/material'
import * as React from 'react'
import { Fade } from 'react-reveal'
import EmailIcon from '@mui/icons-material/Email';

export const Home = (props) => {

    React.useEffect(() => {
        window.scrollTo()
    }, [])

    return (
        <div style={{padding: 0, margin: 0, overflow: 'hidden'}}>
            <div style={{display: 'flex',  paddingTop: '-60px', flexDirection: 'column', minHeight: '100vh'}} id="top">
                <Fade>
                    <div style={{display: 'flex', width: '100%', paddingBottom: 0, marginBottom: 0}}>
                        <img src={process.env.PUBLIC_URL + "images/90proof.jpeg"} style={{width: '100%'}} alt="90 Proof" />
                    </div>
                    <div style={{textAlign: 'center', marginBottom: 0}} className="sanantonio" >
                        <h1 style={{textTransform: 'uppercase', paddingTop: '60px', paddingBottom: '60px', margin: 2}} className="sanantoniotext">San Antonio, Texas</h1>
                    </div>
                    <div style={{display: 'flex', width: '100%'}}>
                        <img src={process.env.PUBLIC_URL + "images/burger.png"} style={{width: '100%'}} alt="90 Proof" />
                    </div>
                </Fade>
            </div>
                

            <Container>
                <div style={{display: 'flex', paddingTop: '75px', flexDirection: 'column'}} id="food">
                    <img src={process.env.PUBLIC_URL + '/images/90Menu1.png'} alt="All day" style={{maxWidth: '80%', padding: '10px', margin: 'auto'}}/>
                    <img src={process.env.PUBLIC_URL + '/images/90Menu2.png'} alt="All day" style={{maxWidth: '80%', padding: '10px', margin: 'auto'}}/>

                    <div style={{display: 'flex', width: '100%', paddingTop: '30px', paddingBottom: '30px'}}>
                        <img src={process.env.PUBLIC_URL + "images/casaazul.jpeg"} style={{width: '100%'}} alt="90 Proof" />
                    </div>

                    <img src={process.env.PUBLIC_URL + '/images/HappyHour.png'} alt="happyhour" style={{maxWidth: '80%', padding: '10px', margin: 'auto'}} />
                </div>
            </Container>

            <Container>
                <div style={{display: 'flex',  paddingTop: '75px', flexDirection: 'column'}} id="location">
                    <h2 style={{textAlign: 'center'}}>Location</h2>
                    <iframe title={'TheRustyNail'} 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3476.8258188360533!2d-98.7304803479412!3d29.37538885673068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865c4531f56c6139%3A0x8edc3f132f9a30cc!2s90%20Proof%20Bar%20%26%20Grill!5e0!3m2!1sen!2sus!4v1672868726797!5m2!1sen!2sus"
                        width="100%" height="300" style={{border: '0', padding: 0, margin: 0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>    
                </div>


                <div style={{display: 'flex',  paddingTop: '150px', paddingBottom: '150px', flexDirection: 'column'}} id="contact">
                    <h2 style={{textAlign: 'center'}}>Contact</h2>
                    <div style={{textAlign: 'center'}}><EmailIcon style={{fontSize: '50px', paddingBottom: '20px'}} /></div>
                    <div style={{textAlign: 'center', lineHeight: '1.5em'}}>For all inquries please email us at 
                        <a href="mailto:contact@therustynailsa.com" className='linkDestyle copperColor' style={{}}> contact@therustynailsa.com</a>
                    </div>
                </div>
            </Container>
            <div>
                <img src={process.env.PUBLIC_URL + "images/bar.jpeg"} style={{width: '100%'}} alt="90 Proof" />
            </div>
        </div>
    )
}

