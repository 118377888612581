import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AnchorLink from 'react-anchor-link-smooth-scroll'

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters style={{overflow: 'hidden', justifyContent: 'space-between'}}>
            
        { /* <img src={process.env.PUBLIC_URL + 'images/nail.svg'} style={{maxWidth: '50px', paddingRight: '10px'}} alt="nail" /> */}
            <AnchorLink href={'#top'} className={'linkDestyle'}>
            <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: 'none', md: 'flex' }}}
            >
                90 PROOF
            </Typography>
            </AnchorLink>

            <AnchorLink href={'#top'} className={'linkDestyle noSelect'} style={{display: { xs: 'flex', md: 'none'}, flexGrow: 1, justifyContent: 'center', alignItems:'center'}}>
                <Typography sx={{display: { xs: 'flex', md: 'none'}, justifyContent: 'center'}} variant="h6">
                90 PROOF
                </Typography>
            </AnchorLink>

          <Box sx={{  display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="90 Proof mobile menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >

            <AnchorLink href={'#food'} className={'linkDestyle'}>
            <MenuItem key={'top'} onClick={handleCloseNavMenu} style={{textAlign: 'center'}}>
                <div className="mobileFont">Food</div>
            </MenuItem>
            </AnchorLink>


            <AnchorLink href={'#location'} className={'linkDestyle'}>
            <MenuItem key={'location'} onClick={handleCloseNavMenu}>
                <div className="mobileFont">Location</div>
            </MenuItem>
            </AnchorLink>

            <AnchorLink href={'#contact'} className={'linkDestyle'}>
            <MenuItem key={'contact'} onClick={handleCloseNavMenu}>
                <div className="mobileFont">Contact</div>
            </MenuItem>
            </AnchorLink>

            </Menu>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <AnchorLink href={'#food'} className={'linkDestyle'}>
                <Button
                        key={'top'}
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: 'white', display: 'block', fontWeight: 'bold', '&:hover': {backgroundColor: '#fefefe', color: '#000'}, marginLeft: '10px', marginRight: '10px' }}
                    >
                    Food
                </Button>
            </AnchorLink> 


            <AnchorLink href={'#location'} className={'linkDestyle'}>
                <Button
                        key={'location'}
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: 'white', display: 'block', fontWeight: 'bold', '&:hover': {backgroundColor: '#fefefe', color: '#000'}, marginLeft: '10px', marginRight: '10px' }}
                    >
                    Location
                </Button>
            </AnchorLink>
            <AnchorLink href={'#contact'} className={'linkDestyle'}>
                <Button
                        key={'contact'}
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: 'white', display: 'block', fontWeight: 'bold', '&:hover': {backgroundColor: '#fefefe', color: '#000'}, marginLeft: '10px', marginRight: '10px' }}
                    >
                    Contact
                </Button>
            </AnchorLink>

          </Box>


        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;